//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import User from '~/components/User.vue';

export default {
  components: { User },

  props: {
    row: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      loading: false,
      modal: false,
      referrals: [],
    };
  },

  methods: {
    ...mapActions('common', ['setGeneralProgress']),
    ...mapActions('userReferralReferrals', ['getReferrals']),

    async loadReferrals() {
      const { userId, referralCount } = this.row;

      if (!referralCount) return;

      this.setGeneralProgress(true);
      const data = await this.getReferrals({
        referrerIdList: [userId],
      });
      this.referrals = data.items;
      this.modal = true;
      this.setGeneralProgress(false);
    },
  },
};
