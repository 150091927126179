var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-referral-program"},[_c('h3',{staticClass:"d-flex mb-6"},[_vm._v(" "+_vm._s(_vm.$t('Referrer:'))+" "),(_vm.referrerLoader)?[_c('v-skeleton-loader',{staticClass:"user-referral-program__referrer-skeleton",attrs:{"type":"text"}})]:[(_vm.referrer.userId)?_c('router-link',{staticClass:"ml-1",attrs:{"to":{
          name: _vm.userRouteName,
          params: {
            id: _vm.referrer.userId,
          },
        }}},[_vm._v(" "+_vm._s(_vm.referrer.userName)+" ")]):[_vm._v(" "+_vm._s(_vm.$t('no referrer'))+" ")]]],2),_c('h3',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t('User referral programs'))+" ")]),_c('div',{staticClass:"user-referral-program__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('div',{staticClass:"user-referral-program__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","highlighted":_vm.extraId},on:{"sort":_vm.applySort,"rowClick":_vm.setExtra},scopedSlots:_vm._u([{key:"referralBalance",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.cumulativeReferralBalancesSum || 0)+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")]}},{key:"income",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.cumulativeReferralIncomeSum || 0)+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")]}},{key:"status",fn:function(ref){
        var row = ref.row;
return [(row.active)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Disabled'))+" ")])]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)]),_c('v-col',{staticClass:"pt-0 pb-0 user-referral-program__extra"},[_c('UserReferralProgramExtra',{attrs:{"data":_vm.extraData,"is-loading":_vm.generalLoader || _vm.loader,"height":"calc(100% - 90px)"},on:{"update":_vm.updateReferralProgram,"add":function($event){return _vm.addUserToReferral(_vm.extraData.name, $event)},"block":function($event){return _vm.blockProgram(_vm.extraData.id)},"unblock":function($event){return _vm.unblockProgram(_vm.extraData.id)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }