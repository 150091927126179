var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"referrals"},[_c('div',{staticClass:"referrals__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"referrals__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px"},on:{"update:selection":function($event){_vm.selection=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.userId,"username":row.userName}})]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v(" r="+_vm._s(row.referralProgramName)+" ")]}},{key:"referralProgramStart",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.referralProgramStart))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.referralProgramStart)))])])]}},{key:"level",fn:function(){return [_vm._v(" 1 ")]},proxy:true},{key:"referrals",fn:function(ref){
var row = ref.row;
return [_c('UserReferralReferralsReferrals',{attrs:{"row":row}})]}},{key:"incomes",fn:function(ref){
var row = ref.row;
return [_c('UserReferralReferralsIncomes',{attrs:{"row":row,"additional-data":_vm.additionalData},on:{"remove":_vm.removeUserFromReferral}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }