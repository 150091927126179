var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"incomes"},[_c('div',{staticClass:"incomes__filters"},[_c('div',{staticClass:"d-flex"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('GetReport',{attrs:{"type":_vm.constant.reports.REPORT_TYPE.REFERRAL_PROGRAM_INCOMES,"command":_vm.requestData}})],1)]),_c('div',{staticClass:"incomes__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px"},on:{"update:selection":function($event){_vm.selection=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateCreated)))])]}},{key:"amount",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$utils.sciToDec(row.amount))+" "+_vm._s(row.currencyTitle)+" ")]),_c('div',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm.$utils.sciToDec(row.cumulativeAmount))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")])]}},{key:"transactionId",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}},{key:"method",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.user.REFERRAL_INCOMES_TYPE_LIST, row.type))+" ")]}},{key:"level",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.level)+" ")]}},{key:"link",fn:function(ref){
var row = ref.row;
return [_vm._v(" r="+_vm._s(row.referralProgramName)+" ")]}},{key:"partner",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.fromUserId,"username":row.fromUserName}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }