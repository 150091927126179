//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        referralProgramStartFrom: '',
        referralProgramStartTo: '',
        levelList: [],
        cumulativeCurrencyId: 0,
      },
    };
  },

  computed: {
    ...mapState('userReferralReferrals', ['filterLists']),
    ...mapGetters('currencies', ['getCurrencyByTitle']),

    currencyList() {
      const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
      const list = PRICE_EQUIVALENT_CURRENCY_LIST.map((e) => this.getCurrencyByTitle(e));
      return list;
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
    const { id } = this.getCurrencyByTitle(PRICE_EQUIVALENT_CURRENCY_LIST[0]);
    this.filters.cumulativeCurrencyId = id;
  },

  methods: {
    ...mapActions('userReferralReferrals', ['setFilters']),
  },
};
