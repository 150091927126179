//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './UserReferralReferralsFilters.vue';
import User from '~/components/User.vue';
import UserReferralReferralsIncomes from './UserReferralReferralsIncomes.vue';
import UserReferralReferralsReferrals from './UserReferralReferralsReferrals.vue';

import tableConfig from './tableConfig';

export default {
  components: {
    Filters,
    User,
    UserReferralReferralsIncomes,
    UserReferralReferralsReferrals,
  },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('userReferralReferrals', ['page', 'pages', 'data', 'additionalData', 'sort']),
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setGeneralProgress', 'setSuccessNotification']),
    ...mapActions('userReferralReferrals', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      getReferralsAction: 'getReferrals',
      removeUserFromReferralAction: 'removeUserFromReferral',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    async removeUserFromReferral(data) {
      this.setGeneralProgress(true);
      await this.removeUserFromReferralAction(data);
      await this.loadData();
      this.setGeneralProgress(false);
      this.setSuccessNotification('User removed from referral program');
    },
  },
};
