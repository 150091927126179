//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import UserReferralProgram from '~/views/UserReferral/UserReferralProgram/UserReferralProgram.vue';
import UserReferralReferrals from '~/views/UserReferral/UserReferralReferrals/UserReferralReferrals.vue';
import UserReferralIncomes from '~/views/UserReferral/UserReferralIncomes/UserReferralIncomes.vue';

export default {
  components: {
    UserReferralProgram,
    UserReferralReferrals,
    UserReferralIncomes,
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('userReferralIncomes', {
      loadUserReferralIncomes: 'loadData',
    }),
    ...mapActions('userReferralReferrals', {
      loadUserReferralReferrals: 'loadData',
    }),
  },
};
