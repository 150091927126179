export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'User',
    },
    cell: {
      type: 'slot',
      name: 'user',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Name',
    },
    cell: {
      type: 'slot',
      name: 'name',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Start',
    },
    cell: {
      type: 'slot',
      name: 'referralProgramStart',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'LvL',
    },
    cell: {
      type: 'slot',
      name: 'level',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Referrals',
    },
    cell: {
      type: 'slot',
      name: 'referrals',
    },
  },
  {
    width: 50,
    header: {
      type: 'text',
      caption: 'Incomes',
    },
    cell: {
      type: 'slot',
      name: 'incomes',
    },
  },
];
