//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        // string: '',
        // array: [],
        // dateFrom: '',
        // dateTo: '',
        cumulativeCurrencyId: 0,
      },
    };
  },

  computed: {
    ...mapState('userReferralProgram', ['filterLists']),
    ...mapGetters('currencies', ['getCurrencyByTitle']),

    priceEquivalentList() {
      const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
      const list = PRICE_EQUIVALENT_CURRENCY_LIST.map((e) => this.getCurrencyByTitle(e));
      return list;
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
    const { id } = this.getCurrencyByTitle(PRICE_EQUIVALENT_CURRENCY_LIST[0]);
    this.filters.cumulativeCurrencyId = id;
  },

  beforeDestroy() {
    this.setReferrer({});
  },

  methods: {
    ...mapActions('userReferralProgram', ['setFilters']),
    ...mapMutations('userReferralProgram', {
      setReferrer: 'SET_REFERRER',
    }),
  },
};
