export default [
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Link',
    },
    cell: {
      type: 'text',
      value: (e) => `r=${e.name}`,
    },
  },
  // {
  //   width: 28,
  //   header: {
  //     type: 'text',
  //     caption: 'Name',
  //   },
  //   cell: {
  //     type: 'text',
  //     value: (e) => e.name,
  //   },
  // },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Referral balance',
    },
    cell: {
      type: 'slot',
      name: 'referralBalance',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Income',
    },
    cell: {
      type: 'slot',
      name: 'income',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
];
