//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import User from '~/components/User.vue';
import UserSelect from '~/components/UserSelect.vue';

export default {

  components: { User, UserSelect },
  props: {
    data: { type: Object, default: () => ({}) },
    height: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      model: {},
      currentLevel: 0,
      referralsList: {
        modal: false,
        users: [],
      },
      addUser: {
        userId: '',
      },
    };
  },

  computed: {
    ...mapState('userReferralProgram', ['additionalData']),

    id() {
      const { id } = this.data;
      return id;
    },

    fixItems: () => ([
      { id: false, caption: 'No' },
      { id: true, caption: 'Yes' },
    ]),

    levels() {
      const { levels } = this.data;
      return levels || [];
    },

    currentLevelInfo() {
      const { levels, currentLevel } = this;
      const level = levels.find((e) => e.level === currentLevel);
      return level;
    },

    currentLevelIncomes() {
      const { currentLevelInfo } = this;
      if (!currentLevelInfo) return 0;

      const cumulativeP2PIncomes = currentLevelInfo.cumulativeP2PIncomes || 0;
      const cumulativeParaminingIncomes = currentLevelInfo.cumulativeParaminingIncomes || 0;
      const cumulativeTradeIncomes = currentLevelInfo.cumulativeTradeIncomes || 0;
      const result = cumulativeP2PIncomes + cumulativeParaminingIncomes + cumulativeTradeIncomes;
      return result.toFixed(2);
    },
  },

  watch: {
    id() {
      this.setModel();
      this.initialSetCurrentLevel();
    },
  },

  methods: {
    ...mapActions('common', ['setGeneralProgress']),
    ...mapActions('userReferralProgram', ['getReferralsByParams']),

    setModel() {
      const {
        buyFee,
        sellFee,
        p2PFee,
        paraminingFee,
        fix,
      } = this.data;
      this.model = {
        buyFee,
        sellFee,
        p2PFee,
        paraminingFee,
        fix,
      };
    },

    initialSetCurrentLevel() {
      const { levels } = this;
      if (levels.length) {
        const { level } = levels[0];
        this.currentLevel = level;
      }
    },

    save() {
      const { id, applyFor } = this;

      const {
        buyFee,
        sellFee,
        p2PFee,
        paraminingFee,
      } = this.model;

      const payload = {
        referralProgramId: id,
        buyFee,
        sellFee,
        p2PFee,
        paraminingFee,
        fix: applyFor,
      };

      this.$emit('update', payload);
    },

    async showCurrentLevelReferrals() {
      const { id, currentLevel } = this;

      this.setGeneralProgress(true);
      const { items } = await this.getReferralsByParams({
        referralProgramIdList: [id],
        levelList: [currentLevel],
        limit: 1000,
      });
      this.referralsList.users = items;
      this.referralsList.modal = true;
      this.setGeneralProgress(false);
    },

    async addUserToReferral() {
      const { userId } = this.addUser;
      this.$emit('add', userId);
    },

    setUserId(value) {
      this.addUser.userId = value;
    },

    block() {
      this.$emit('block');
    },

    unblock() {
      this.$emit('unblock');
    },
  },
};
