//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import UserReferralProgramExtra from './UserReferralProgramExtra.vue';
import Filters from './UserReferralProgramFilters.vue';

import tableConfig from './tableConfig';
import navigationUser from '~/config/navigationUser';

export default {
  components: { UserReferralProgramExtra, Filters },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      referrerLoader: false,
      extraId: 0,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('userReferralProgram', [
      'page',
      'pages',
      'data',
      'additionalData',
      'referrer',
      'sort',
    ]),

    extraData() {
      const { extraId, data } = this;
      const current = data.find((e) => e.id === extraId);
      if (current) {
        return current;
      }
      return {};
    },

    userRouteName() {
      return navigationUser[0]?.routeName || 'user-id';
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('userReferralProgram', {
      loadDataAction: 'loadData',
      loadUserReferrer: 'loadUserReferrer',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      updateReferralProgramAction: 'updateReferralProgram',
    }),
    ...mapActions('referralProgramPrograms', {
      addUserToReferralAction: 'addUserToReferral',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await Promise.allSettled([
        this.loadDataAction(data),
        this.initialLoadUserReferrer(),
      ]);
      this.initialSetExtra();
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    initialSetExtra() {
      const { data } = this;
      if (data && data.length) {
        this.extraId = data[0].id;
      }
    },

    setExtra(data) {
      this.extraId = data.id;
    },

    async updateReferralProgram(data) {
      this.setGeneralProgress(true);
      try {
        await this.updateReferralProgramAction(data);
        this.extraId = 0; // чтобы в Extra сработал watcher и обновилось состояние инпутов
        this.loadData();
        this.setSuccessNotification('Referral program updated');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },

    async initialLoadUserReferrer() {
      const { userId } = this.referrer;
      if (!userId) {
        this.referrerLoader = true;
        try {
          await this.loadUserReferrer();
        } catch (error) {
          this.setErrorNotification(error.message);
        }
        this.referrerLoader = false;
      }
    },

    async addUserToReferral(referral, userId) {
      this.setGeneralProgress(true);
      try {
        await this.addUserToReferralAction({ referral, userId });
        await this.loadDataAction();
        this.setSuccessNotification(this.$t('User was added to the referral program $referral', { referral }));
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },

    async blockProgram(referralProgramId) {
      this.setGeneralProgress(true);
      await this.updateReferralProgramAction({
        referralProgramId,
        active: false,
      });
      await this.loadDataAction();
      this.setGeneralProgress(false);
      this.setSuccessNotification('Referral program blocked');
    },

    async unblockProgram(referralProgramId) {
      this.setGeneralProgress(true);
      await this.updateReferralProgramAction({
        referralProgramId,
        active: true,
      });
      await this.loadDataAction();
      this.setGeneralProgress(false);
      this.setSuccessNotification('Referral program unblocked');
    },
  },
};
