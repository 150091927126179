export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Date',
    },
    cell: {
      type: 'slot',
      name: 'date',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Amount',
    },
    cell: {
      type: 'slot',
      name: 'amount',
    },
  },
  // {
  //   width: 10,
  //   header: {
  //     type: 'text',
  //     caption: 'Price equivalent',
  //   },
  //   cell: {
  //     type: 'slot',
  //     name: 'priceEquivalent',
  //   },
  // },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Transaction Id',
    },
    cell: {
      type: 'slot',
      name: 'transactionId',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Method',
    },
    cell: {
      type: 'slot',
      name: 'method',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'LvL',
    },
    cell: {
      type: 'slot',
      name: 'level',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Link',
    },
    cell: {
      type: 'slot',
      name: 'link',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Partner',
    },
    cell: {
      type: 'slot',
      name: 'partner',
    },
  },
];
