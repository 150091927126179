//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './UserReferralIncomesFilters.vue';
import User from '~/components/User.vue';
import GetReport from '~/components/GetReport.vue';

import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  components: { Filters, User, GetReport },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('userReferralIncomes', [
      'page',
      'pages',
      'data',
      'additionalData',
      'sort',
    ]),
    ...mapGetters('userReferralIncomes', ['requestData']),
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setGeneralProgress', 'setSuccessNotification']),
    ...mapActions('userReferralIncomes', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },
  },
};
