//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    row: { type: Object, default: () => ({}) },
    additionalData: { type: Object, default: () => ({}) },
  },

  methods: {
    removeUserFromReferral() {
      const { userId } = this.row;

      this.$emit('remove', {
        userId,
      });
    },
  },
};
