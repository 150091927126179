var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-space-between align-center incomes"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-6 incomes__col"},[_c('div',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$t('Trading'))+" ")]),_c('div',[(_vm.row.cumulativeTradeIncomes)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"border-bottom":"1px dotted"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.row.cumulativeTradeIncomes.toFixed(2))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")])]}}],null,false,2631519465)},[_c('div',_vm._l((_vm.row.tradeIncomes),function(item,i){return _c('div',{key:'trade-incomes_' + i},[_vm._v(" "+_vm._s(_vm.$utils.sciToDec(item.referralIncomeSum))+" "+_vm._s(item.currencyTitle)+" ")])}),0)])]:_c('span',[_vm._v("0")])],2)]),_c('div',{staticClass:"mr-6 incomes__col"},[_c('div',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$t('Staking'))+" ")]),_c('div',[(_vm.row.cumulativeParaminingIncomes)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"border-bottom":"1px dotted"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.row.cumulativeParaminingIncomes.toFixed(2))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")])]}}],null,false,1791179463)},[_c('div',_vm._l((_vm.row.paraminingIncomes),function(item,i){return _c('div',{key:'trade-incomes_' + i},[_vm._v(" "+_vm._s(_vm.$utils.sciToDec(item.referralIncomeSum))+" "+_vm._s(item.currencyTitle)+" ")])}),0)])]:_c('span',[_vm._v("0")])],2)]),_c('div',{staticClass:"mr-6 incomes__col"},[_c('div',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$t('P2P'))+" ")]),_c('div',[(_vm.row.cumulativeP2PIncomes)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"border-bottom":"1px dotted"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.row.cumulativeP2PIncomes.toFixed(2))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")])]}}],null,false,2920048797)},[_c('div',_vm._l((_vm.row.p2PIncomes),function(item,i){return _c('div',{key:'trade-incomes_' + i},[_vm._v(" "+_vm._s(_vm.$utils.sciToDec(item.referralIncomeSum))+" "+_vm._s(item.currencyTitle)+" ")])}),0)])]:_c('span',[_vm._v("0")])],2)])]),_c('div',{},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.removeUserFromReferral}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-minus-circle-outline ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }